import React from 'react'

const Experience = props => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="experience">
      <div className="my-auto">
        <h2 className="mb-5">Experience</h2>

        <div className="resume-item d-flex flex-column flex-md-row mb-5">
          <div className="resume-content mr-auto">
            <h3 className="mb-0">Programmer</h3>
            <div className="subheading mb-3">Acuity Insurance | Sheboygan, WI</div>
            <p>Data Warehouse Team</p>
            <p>DB2 database managment</p>
            <p>COBOL and JCL programming</p>
            <p>DOJO web development</p>
            <p>JUnit and JMock testing environments</p>

          </div>
          <div className="resume-date text-md-right">
            <span className="text-primary">June 2020 - Present</span>
          </div>
        </div>

        <div className="resume-item d-flex flex-column flex-md-row mb-5">
          <div className="resume-content mr-auto">
            <h3 className="mb-0">Software Engineering Summer Intern</h3>
            <div className="subheading mb-3">Northwestern Mutual | Milwaukee, WI</div>
            <p>Worked as a full stack developer on a team designing, coding, and presenting a web application using React and Express.</p>
            <p>Designed and coded a RESTful backend using Express.js</p>
            <p>Worked with JUnit testing for a Java application</p>
            <p>Mentored High School interns by explaining React, Node, and other tools used to develop the web application</p>
            <p>Team used agile process to develop</p>
          </div>
          <div className="resume-date text-md-right">
            <span className="text-primary">May - August | 2018 and 2019</span>
          </div>
        </div>

        <div className="resume-item d-flex flex-column flex-md-row mb-5">
          <div className="resume-content mr-auto">
            <h3 className="mb-0">Web Developer/Informatics</h3>
            <div className="subheading mb-3">Benner Library | Bourbonnais, IL</div>
            <p>Student Employee of the Year Nominee - April 2019</p>
            <p>Develop and maintain web applications used by the library using Ember framework</p>
            <p>Assist patrons with all computer related questions;
            offer consultation with device setup and printing issues.</p>
            <p>Provide technical support for classes that meet in the Instruction Lab</p>
            <p>Implemented features on Substitutions app such as data
            validation, data analysis, and user interface features</p>
          </div>
          <div className="resume-date text-md-right">
            <span className="text-primary">August 2016 - May 2020</span>
          </div>
        </div>

      </div>

    </section>
  )
}

export default Experience
