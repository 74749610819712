import React from 'react'
import Scroll from 'react-scroll'
import PropTypes from 'prop-types'

const Navbar = props => {
  const scroller = Scroll.scroller

  function scrollTo (element) {
    scroller.scrollTo(element, {
      duration: 1500,
      delay: 100,
      smooth: true
    })
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
      <a className="navbar-brand js-scroll-trigger" href="#page-top">
        <span className="d-block d-lg-none">Joshua Docea</span>
        <span className="d-none d-lg-block">
          <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src="/profilePic.jpg" alt="" />
        </span>
      </a>
      <button id="navButton" className="navbar-toggler" type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a data-toggle={props.mobile ? 'collapse' : ''}
              data-target="#navbarSupportedContent" className="nav-link js-scroll-trigger"
              style={{ cursor: 'pointer' }}
              onClick={(e) => scrollTo('about')}>About</a>
          </li>
          <li className="nav-item">
            <a data-toggle={props.mobile ? 'collapse' : ''}
              data-target="#navbarSupportedContent" className="nav-link js-scroll-trigger"
              style={{ cursor: 'pointer' }}
              onClick={(e) => scrollTo('experience')}>Experience</a>
          </li>
          <li className="nav-item">
            <a data-toggle={props.mobile ? 'collapse' : ''}
              data-target="#navbarSupportedContent" className="nav-link js-scroll-trigger"
              style={{ cursor: 'pointer' }}
              onClick={(e) => scrollTo('education')}>Education</a>
          </li>
          <li className="nav-item">
            <a data-toggle={props.mobile ? 'collapse' : ''}
              data-target="#navbarSupportedContent" className="nav-link js-scroll-trigger"
              style={{ cursor: 'pointer' }}
              onClick={(e) => scrollTo('skills')}>Skills</a>
          </li>
          <li className="nav-item">
            <a data-toggle={props.mobile ? 'collapse' : ''}
              data-target="#navbarSupportedContent" className="nav-link js-scroll-trigger"
              style={{ cursor: 'pointer' }}
              onClick={(e) => scrollTo('interests')}>Interests</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  mobile: PropTypes.bool
}

export default Navbar
