import React from 'react'

const Interests = props => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="interests">
      <div className="my-auto">
        <h2 className="mb-5">Interests</h2>
        <div className="resume-item d-flex flex-column flex-md-row mb-5">
          <div className="resume-content mr-auto">
            <h4>Professional Interests</h4>
            <p>I am interested in data analytics and machine learning as future career opportunities.
             I enjoy working with data and finding patterns within data. I am constantly learning more about machine learning and hope to expand my career in this area.</p>
          </div>
        </div>
        <div className="resume-item d-flex flex-column flex-md-row mb-5">
          <div className="resume-content mr-auto">
            <h4>Personal Interests</h4> <p>In my spare time, I like to rock climb. I have been climbing since I was eight years old. I also enjoy other
          outdoor activities such as hiking, biking, and running.
          Additionally, I like to perform magic tricks. I specifically like performing card tricks.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Interests
