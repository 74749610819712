import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Skills = props => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="skills">
      <div className="my-auto">
        <h2 className="mb-5">Skills</h2>

        <div className="subheading mb-3">Programming Languages &amp; Tools</div>
        <ul className="list-inline dev-icons">
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'html5']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'css3-alt']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'js-square']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'angular']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'react']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'ember']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'node']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'gulp']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'npm']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'java']} /></i>
          </li>
          <li className="list-inline-item">
            <i><FontAwesomeIcon icon={['fab', 'python']} /></i>
          </li>
        </ul>

        <div className="subheading mb-3">Workflow</div>
        <ul className="fa-ul mb-0">
          <li>
            <FontAwesomeIcon icon='check' />
            Agile Development &amp; Scrum
          </li>
          <li>
            <FontAwesomeIcon icon='check' />
            Test Driven Development
          </li>
          <li>
            <FontAwesomeIcon icon='check' />
              JIRA
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Skills
