import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const About = props => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex d-column" id="about">
      <div className="my-auto">
        <h1 className="mb-0">Joshua
          <span className="text-primary"> Docea</span>
        </h1>
        <div className="subheading mb-5">
          <p>B.S. Computer Science and
          Minor in Data Science</p>
          <p>Master&apos;s in Data Science</p>
        </div>

        <p className="lead mb-5">
        I am experienced in full stack web development and various JavaScript frameworks.
        I also have extensive experience with Java and object oriented programming.
        I am a technology enthusiast and enjoy learning about new ways to use technology.
        I am a quick learner and constantly looking for innovative ways to solve problems.</p>
        <div className="social-icons">

          <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/joshua-docea/">
            <FontAwesomeIcon icon={['fab', 'linkedin']} />
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://github.com/jddocea">
            <FontAwesomeIcon icon={['fab', 'github']} />
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/jddocea">
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
        </div>
      </div>
    </section>
  )
}
export default About
