import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/analytics'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Element } from 'react-scroll'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Navbar from './navbar'
import About from './sections/about'
import Education from './sections/education'
import Experience from './sections/experience'
import Skills from './sections/skills'
import Interests from './sections/interests'

require('firebase/firestore')

class App extends Component {
  updateAlert () {
    this.setState({ errorMessage: '' })
  }

  constructor (props) {
    super(props)
    this.state = {
      welcomeMessage: 'Welcome To My Site',
      formInfo: {
        email: '',
        name: '',
        message: ''
      },
      windowWidth: window.innerWidth,
      mobile: false,
      errorMessage: ''
    }
    this.updateAlert = this.updateAlert.bind(this)
    this.updateEmail = this.updateEmail.bind(this)
    this.updateName = this.updateName.bind(this)
    this.updateMessage = this.updateMessage.bind(this)
    this.addEntry = this.addEntry.bind(this)
    library.add(fab, faCheckSquare, faCoffee, faCheck)
    // Initialize Firebase
    const config = {
      apiKey: process.env.APIKEY,
      authDomain: 'justjoshin-5f827.firebaseapp.com',
      databaseURL: process.env.DATA_URL,
      projectId: 'justjoshin-5f827',
      storageBucket: 'justjoshin-5f827.appspot.com',
      messagingSenderId: process.env.MESSID,
      appId: process.env.APPID
    }
    firebase.initializeApp(config)
    // TODO: add express here to grab doc id from params and delete entry
  }

  componentDidMount () {
    this.updateDimentions()
    window.addEventListener('resize', this.updateDimentions.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimentions.bind(this))
  }

  componentDidUpdate () {
  }

  updateDimentions () {
    if (window.innerWidth < 992) {
      this.setState({ mobile: true })
    } else {
      this.setState({ mobile: false })
    }
  }

  addEntry () {
    const data = {
      name: this.state.formInfo.name,
      email: this.state.formInfo.email,
      message: this.state.formInfo.message,
      created: firebase.firestore.FieldValue.serverTimestamp()
    }
    this.setState({ errorMessage: '' })
    if (data.email === '' || !data.email.includes('@') || !data.email.includes('.')) {
      this.setState({ errorMessage: 'Enter a valid email' })
      return
    } else if (data.name === '') {
      this.setState({ errorMessage: 'Enter a name' })
      return
    }
    // Add a new document with ID of email
    firebase.firestore().collection('entries').add(data)
      .then(function (docRef) {

      })
      .catch(function (error) {
        console.error('Error adding document: ', error)
      })
      .then(this.setState(
        {
          errorMessage: 'Submitted',
          formInfo:
          { name: '', email: '', message: '' }
        }))
  }

  deleteEntry ({ match }) {
    const id = match.params.id
    firebase.firestore().collection('entries').doc(id).delete()
      .catch(function (error) {
        console.error('Error Deleting Document', error)
      })
    return (
      <div id='alertBox' className="alert alert-success alert-dismissible fade show" role="alert">
        Successfully removed email from database
      </div>
    )
  }

  updateEmail (e) {
    this.setState({
      formInfo: {
        email: e.target.value,
        name: this.state.formInfo.name,
        message: this.state.formInfo.message
      }
    })
  }

  updateName (e) {
    this.setState({
      formInfo: {
        name: e.target.value,
        email: this.state.formInfo.email,
        message: this.state.formInfo.message
      }
    })
  }

  updateMessage (e) {
    this.setState({
      formInfo: {
        message: e.target.value,
        name: this.state.formInfo.name,
        email: this.state.formInfo.email
      }
    })
  }

  render () {
    return (

      <div id="page-top">
        <Router>
          <Route path='/entry/:id' component={this.deleteEntry}/>
        </Router>
        <Navbar mobile={this.state.mobile}/>

        <div className="container-fluid p-0">
          <Element name='about'></Element>
          <About />

          <hr className="m-0"/>
          <Element name='experience'></Element>
          <Experience />

          <hr className="m-0" />
          <Element name='education'></Element>
          <Education />

          <hr className="m-0" />
          <Element name='skills'></Element>
          <Skills />

          <hr className="m-0"/>
          <Element name='interests'></Element>
          <Interests />

        </div>

      </div>
    )
  }
}

export default App
