import React from 'react'

const Education = props => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="education">
      <div className="my-auto">
        <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row mb-5">
            <div className="resume-content mr-auto">
              <h3 className="mb-0">Northwestern University</h3>
              <br />
              <div className="subheading mb-3">Master&apos;s in Data Science</div>
              <p>Analytics and Modeling Emphasis</p>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 2021 - March 2023</span>
            </div>
          </div>

         <div className="resume-item d-flex flex-column flex-md-row mb-5">
          <div className="resume-content mr-auto">
            <h3 className="mb-0">Olivet Nazarene University</h3>
            <br />
            <div className="subheading mb-3">Bachelor of Science - Computer Science</div>
            <div className="subheading mb-3">Minor in Data Science</div>
            <p>Information and Technology Emphasis</p>
            <p>GPA: 3.596</p>
            <p>Scrum Master and Technical Specialist in CSIS 455: Agile in Practice</p>
            <p>Wrote an API using PHP</p>
            <p>Experience with Google Cloud Platform</p>
          </div>
          <div className="resume-date text-md-right">
            <span className="text-primary">August 2016 - May 2020</span>
          </div>
        </div>

      </div>
    </section>
  )
}
export default Education
